import { ModalsBackgroundCheckingsComponent } from '@admin/shared/components/modals/background-checkings/modals.background-checkings.component';
import { ModalsDetailsComponent } from '@admin/shared/components/modals/details/modals.details.component';
import { ModalsImportComponent } from '@admin/shared/components/modals/import/modals.import.component';
import { ModalsRegisterComponent } from '@admin/shared/components/modals/register/modals.register.component';
import { ModalsContractSettingComponent } from '@admin/shared/components/modals/contract-setting/modals.contract-setting.component';
import { AdminNavbarComponent } from '@admin/shared/components/navbar/navbar.component';
import { AdminTopbarComponent } from '@admin/shared/components/topbar/topbar.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxFileDropModule } from 'ngx-file-drop';

const components = [
  AdminNavbarComponent,
  AdminTopbarComponent,

  // Modals
  ModalsRegisterComponent,
  ModalsDetailsComponent,
  ModalsBackgroundCheckingsComponent,
  ModalsImportComponent,
  ModalsContractSettingComponent
];

@NgModule({
  imports: [SharedModule, NgxFileDropModule],
  exports: [SharedModule, BsDatepickerModule, components],
  declarations: [components]
})
export class AdminSharedModule {}
