import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const token = this._commonEnvironments.getToken();

    if (!!token) {
      const payload: IPayload = jwtDecode(token);
      const roles = payload.roles;

      if (roles.includes('admin')) {
        return true;
      } else {
        this._commonEnvironments.cleanStorage();
        this._router.navigate(['/login'], {
          queryParams: {
            returnUrl: state.url
          }
        });

        return false;
      }
    }

    this._commonEnvironments.cleanStorage();
    this._router.navigate(['/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });

    return false;
  }
}
