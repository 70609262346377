import { Component, Input, OnInit } from '@angular/core';
import { IBackgroundChecking } from '@shared/interfaces/background-checking.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { BackgroundCheckingsService } from '@shared/services/background-checkings.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { noop, Subject } from 'rxjs';

@Component({
  selector: 'app-modals-background-checkings',
  templateUrl: './modals.background-checkings.component.html'
})
export class ModalsBackgroundCheckingsComponent implements OnInit {
  @Input() public openModal: Subject<IBackgroundChecking>;

  public title = String('MODALS.BACKGROUND_CHECKINGS.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');
  public isButtonsDisabled = Boolean(false);
  public isChecked = Boolean(false);
  public errors: any[] = [];

  public backgroundChecking: IBackgroundChecking;
  public document: IDocument;

  public file: File;

  public description = String('MODALS.BACKGROUND_CHECKINGS.DESCRIPTION');

  public closeModal: Subject<boolean> = new Subject<boolean>();

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _backgroundCheckings: BackgroundCheckingsService,
    private readonly _logger: LoggerService,
    private readonly _toast: ToastService
  ) {}

  public get dropZoneLabel(): string {
    return this.isChecked ? this.file.name : `Drop .pdf file here`;
  }

  @Input() public afterSubmit: any = () => noop;

  ngOnInit(): void {
    this.openModal.subscribe((bc: IBackgroundChecking) => {
      if (!!bc) {
        this.backgroundChecking = bc;
      }
    });
  }

  public onDismiss() {
    this.isButtonsDisabled = false;
    this.errors = [];
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    this.isButtonsDisabled = true;

    const id = this.backgroundChecking.id;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          const size = file.size / 1000000;

          if (size > 15) {
            return this._toast.error('File is too big');
          }

          this.file = file;
          const url = `POST /candidates/${id}}/documents`;
          this._backgroundCheckings.postDocument(file, { id }).subscribe(
            (res: IBackgroundChecking) => {
              this._logger.info(this.constructorName, url, res);

              this._toast.success('Document uploaded');
              this.isButtonsDisabled = false;

              this.closeModal.next();
            },
            (err: any) => {
              this._logger.error(this.constructorName, url, err);

              this.isButtonsDisabled = false;
              this._toast.error(err[0].detail);
            }
          );

          return fileEntry;
        });
      }
    }
  }
}
