import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private readonly _translate: TranslateService) {
    const lng = 'en';
    this._translate.setDefaultLang(lng);
    this._translate.use(lng);
  }
}
