import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IVerification } from '@shared/interfaces/verification.interface';
import { Verification } from '@shared/models/verification.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-details',
  templateUrl: './modals.details.component.html'
})
export class ModalsDetailsComponent implements OnInit {
  @Input() public openModal: Subject<any>;
  @Input() public isAdminVerifications = Boolean(false);

  public title = String('MODALS.DETAILS.TITLE');
  public description = String('MODALS.DETAILS.DESCRIPTION');

  public profile_url = String();
  public errors: IAPIErrorObject[] = [];
  public verification: any;
  public closeModal: Subject<any> = new Subject<any>();

  private readonly ENDPOINTS_APP: string = String(
    environment.ENDPOINTS.FRONT_END.APP
  );

  constructor() {}

  ngOnInit(): void {
    this.profile_url = `${this.ENDPOINTS_APP}/`;
    this.openModal.subscribe((v: IVerification) => {
      this.verification = new Verification(v);

      if (
        this.isAdminVerifications &&
        (this.verification.username || this.verification.id)
      ) {
        this.profile_url += this.verification.username || this.verification.id;
      }
    });
  }
}
