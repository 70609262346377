import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IAPIArrayData, IAPIData } from '@shared/interfaces/api.interface';
import { IContractSetting } from '@admin/shared/interfaces/contract-setting.interface';
import { ContractSetting } from '@admin/shared/models/contract-setting.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractSettingsService {
  private readonly ENDPOINT_V3: string = String(
    `${environment.ENDPOINTS.BACK_END.V3}/admin/hiring_firms`
  );

  constructor(private readonly _http: HttpClient) {}

  public listByHiringFirm(hiringFirmId: string): Observable<IContractSetting[]> {
    return this._http
      .get<IAPIArrayData>(`${this.hiringFirmEndpoint(hiringFirmId)}`)
      .pipe(map((res: IAPIArrayData) => ContractSetting.fromAPIArray(res)));
  }

  public get(hiringFirmId: string, id: string): Observable<IContractSetting> {
    return this._http
      .get<IAPIData>(`${this.hiringFirmEndpoint(hiringFirmId)}/${id}`)
      .pipe(map((res: IAPIData) => ContractSetting.fromAPI(res)));
  }

  public patch(hiringFirmId: string, body: IAPIData): Observable<IContractSetting> {
    return this._http
      .patch<IAPIData>(`${this.hiringFirmEndpoint(hiringFirmId)}/${body.data.id}`, body)
      .pipe(map((res: IAPIData) => ContractSetting.fromAPI(res)));
  }

  public post(hiringFirmId: string, body: IAPIData): Observable<IContractSetting> {
    return this._http
      .post<IAPIData>(`${this.hiringFirmEndpoint(hiringFirmId)}`, body)
      .pipe(map((res: IAPIData) => ContractSetting.fromAPI(res)));
  }

  private hiringFirmEndpoint(hiringFirmId: string): string {
    return `${this.ENDPOINT_V3}/${hiringFirmId}/contract_settings`;
  }
}
