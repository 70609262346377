import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IPlan } from '@shared/interfaces/plan.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { User } from '@shared/models/user.model';
import { HiringFirmsService } from '@shared/services/hiring-firms.service';
import { LoggerService } from '@shared/services/logger.service';
import { EmailValidityValidator } from '@shared/validators/emailValidity.validators';
import { PasswordValidator } from '@shared/validators/password.validators';

@Component({
  selector: 'app-modals-register',
  templateUrl: './modals.register.component.html'
})
export class ModalsRegisterComponent
  extends ModalDirective<IRecruiter>
  implements OnInit
{
  public isButtonsDisabled = Boolean(false);
  public title = String('MODALS.REGISTER.TITLE');
  public submitKey = String('BUTTONS.SUBMIT');
  public entry: FormGroup;
  public plans: IPlan[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _hiringFirms: HiringFirmsService,
    private readonly _logger: LoggerService,

    private readonly _emailValidityValidator: EmailValidityValidator
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      const apiData = new User(value).apiData;
      this.isButtonsDisabled = true;
      this.entry.disable();
      this.errors = [];

      apiData.data.attributes['company_name'] = this.entry.get('company_name').value

      const url = 'POST /hiring_firms_with_root';
      this._hiringFirms.post(apiData).subscribe(
        (res: IHiringFirm) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);
          this.isButtonsDisabled = false;
        },
        (err: IAPIErrorObject[]) => {
          this._logger.error(this.constructorName, url, err);

          this.entry.enable();
          this.isButtonsDisabled = false;
          this.errors = err;
        }
      );
    }
  }

  public resetForm() {
    this.entry.enable();
    super.resetForm();
  }

  public onDismiss() {
    this.entry.enable();
    this.errors = [];

    super.onDismiss();
  }

  protected createForm() {
    this.entry = this._fb.group(
      {
        first_name: [''],
        last_name: [''],
        email: [
          '',
          {
            validators: [
              Validators.email,
              this._emailValidityValidator.required
            ],
            asyncValidators: [this._emailValidityValidator.validatesAsync]
          }
        ],
        password: ['', [Validators.required]],
        password_confirmation: ['', [Validators.required]],
        company_name: ['', [Validators.required]],
        plan_id: ['']
      },
      {
        validator: PasswordValidator.MatchPassword(
          'password',
          'password_confirmation'
        )
      }
    );
  }
}
