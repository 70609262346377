import {
  APIDate,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject
} from '@shared/interfaces/api.interface';
import { IContractSetting } from '@admin/shared/interfaces/contract-setting.interface';

export class ContractSetting implements IContractSetting {
  public static readonly TYPE: string = String('ContractSetting');

  id: APIuuid;
  hiring_firm_id: APIuuid;
  is_active: boolean;
  candidates_usage_limit: number;
  remote_end_term_at: APIDate;
  remote_start_term_at: APIDate;
  last_synced_remote_settings_at: APIDate;
  inactivated_at: APIDate;
  created_at: APIDate;
  updated_at: APIDate;

  constructor(partial: Partial<IContractSetting>) {
    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: ContractSetting.TYPE,
        attributes: {
          hiring_firm_id: this.hiring_firm_id,
          is_active: this.is_active,
          candidates_usage_limit: this.candidates_usage_limit,
          remote_end_term_at: this.remote_end_term_at,
          remote_start_term_at: this.remote_start_term_at,
          last_synced_remote_settings_at: this.last_synced_remote_settings_at,
          inactivated_at: this.inactivated_at,
          created_at: this.created_at,
          updated_at: this.updated_at
        }
      }
    } as IAPIData;

    return data;
  }

  static fromAPIArray(res: IAPIArrayData): IContractSetting[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      if (!(data && data.type === ContractSetting.TYPE)) {
        throw new Error(`There was a problem parsing ${ContractSetting.TYPE} API data`);
      }

      const setting = new ContractSetting({
        id: data.id,
        ...data.attributes
      });

      return setting;
    });
  }

  static fromAPI(res: IAPIData): IContractSetting {
    const data = res.data;
    if (!(data && data.type === ContractSetting.TYPE)) {
      throw new Error(`There was a problem parsing ${ContractSetting.TYPE} API data`);
    }

    return new ContractSetting({
      id: data.id,
      ...data.attributes
    });
  }
}
