import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class AdminTopbarComponent implements OnInit {
  public links: any[] = [
    {
      url: '/account/information',
      icon: 'lnr-user'
    },
    {
      url: '/logout',
      icon: 'lnr-exit'
    }
  ];

  constructor() {}

  ngOnInit(): void {}
}
