import { IContractSetting } from '@admin/shared/interfaces/contract-setting.interface';
import { ContractSetting } from '@admin/shared/models/contract-setting.model';
import { ContractSettingsService } from '@admin/shared/services/contract-settings.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAPIErrorObject } from '@shared/interfaces/api.interface';
import { LoggerService } from '@shared/services/logger.service';

@Component({
  selector: 'app-modals-contract-setting',
  templateUrl: './modals.contract-setting.component.html'
})
export class ModalsContractSettingComponent
  extends ModalDirective<IContractSetting>
  implements OnInit
{
  @Input() hiringFirmId: string;
  public isButtonsDisabled = Boolean(false);
  public title = 'New Contract Setting';
  public submitKey = String('BUTTONS.SUBMIT');
  public entry: FormGroup;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _contractSettings: ContractSettingsService,
    private readonly _logger: LoggerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.isButtonsDisabled = true;
      this.entry.disable();
      this.errors = [];
      value['hiring_firm_id'] = this.hiringFirmId;
      const apiData = new ContractSetting(value).apiData;

      const url = 'POST /admin/hiring_firms/:hf_id/contract_settings';
      this._contractSettings.post(this.hiringFirmId, apiData).subscribe(
        (res: IContractSetting) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);
          this.isButtonsDisabled = false;
        },
        (err: IAPIErrorObject[]) => {
          this._logger.error(this.constructorName, url, err);

          this.entry.enable();
          this.isButtonsDisabled = false;
          this.errors = err;
        }
      );
    }
  }

  public resetForm() {
    this.entry.enable();
    super.resetForm();
  }

  public onDismiss() {
    this.entry.enable();
    this.errors = [];

    super.onDismiss();
  }

  protected createForm() {
    this.entry = this._fb.group(
      {
        candidates_usage_limit: ['']
      }
    );
  }
}
