import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class AdminNavbarComponent implements OnInit {
  public openRegisterModal: Subject<boolean> = new Subject();

  public links: {
    id: number;
    label: string;
    isCollapsed: boolean;
    items: { label: string; icon: string; url: string }[];
  }[] = [
    {
      id: 0,
      label: 'Overview',
      isCollapsed: false,
      items: [
        {
          label: 'dashboard',
          url: '/dashboard',
          icon: 'lnr-chart-bars'
        },
        {
          label: 'kpis',
          url: '/kpis',
          icon: 'lnr-chart-growth'
        },
        {
          label: 'activity',
          url: '/activity',
          icon: 'lnr-papers'
        },
        {
          label: 'messaging stats',
          url: '/messaging-dashboard',
          icon: 'lnr-paper-plane'
        }
      ]
    },
    {
      id: 1,
      label: 'Users',
      isCollapsed: true,
      items: [
        {
          label: 'companies',
          url: '/companies',
          icon: 'lnr-apartment'
        },
        {
          label: 'recruiters',
          url: '/recruiters',
          icon: 'lnr-users2'
        },
        {
          label: 'candidates',
          url: '/candidates',
          icon: 'lnr-clipboard-user'
        }
      ]
    },
    {
      id: 2,
      label: 'References',
      isCollapsed: true,
      items: [
        {
          label: 'references',
          url: '/references',
          icon: 'lnr-contacts'
        },
        {
          label: 'ref. requests',
          url: '/requests',
          icon: 'lnr-paper-plane'
        }
      ]
    },
    {
      id: 3,
      label: 'Feedback',
      isCollapsed: true,
      items: [
        {
          label: 'feedback',
          url: '/feedbacks',
          icon: 'lnr-thumbs-up'
        },
        {
          label: 'Pre-screening',
          url: '/pre-screenings',
          icon: 'lnr-folder-user'
        },
        {
          label: 'Back. checkings',
          url: '/background-checkings',
          icon: 'lnr-license'
        }
      ]
    },
    {
      id: 4,
      label: 'ATS',
      isCollapsed: true,
      items: [
        {
          label: 'employers',
          url: '/employers',
          icon: 'lnr-folder-user'
        }
      ]
    },
    {
      id: 5,
      label: 'Network',
      isCollapsed: true,
      items: [
        {
          label: 'profiles',
          url: '/profiles',
          icon: 'lnr-document2'
        },
        {
          label: 'verifications',
          url: '/verifications',
          icon: 'lnr-shield-check'
        }
      ]
    },
    {
      id: 6,
      label: 'Settings',
      isCollapsed: true,
      items: [
        {
          label: 'templates',
          url: '/templates',
          icon: 'lnr-register'
        }
      ]
    }
  ];

  public rootLinks: { label: string; icon: string; url: string }[] = [];
  public isProduction: boolean = environment.IS_PRODUCTION;
  public isCollapsed = Boolean(true);
  public params: any = {
    environment: environment.ENVIRONMENT
  };

  constructor(private readonly _router: Router) {
    this.afterSubmit = this.afterSubmit.bind(this);
  }

  ngOnInit(): void {}

  public toggleCollapse(l: any) {
    this.links.map((ll: any) => (ll.isCollapsed = true));
    l.isCollapsed = false;
  }

  public openModal() {
    this.openRegisterModal.next();
  }

  public trackByFn(i: any) {
    return i.id;
  }

  public afterSubmit(hf: IHiringFirm) {
    if (hf) {
      this._router.navigate(['/companies', hf.id]);
    }
  }
}
